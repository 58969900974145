import { FC, memo, useMemo } from 'react';
import cn from 'clsx';
import { useRouter } from 'next/router';
import { getRegion } from '@framework/api/utils/maxify';
import getAmountOffLabel from '@utils/get-amount-off-label';
import { MAXIFY_CURRENCY_MAP_PER_REGION } from '@constants';
import usePrice from '@framework/use-price';
import { useTranslation as t } from '@utils/hooks';
import * as styles from './styles';

type Props = {
  isLowStock?: boolean;
  isOutOfStock?: boolean;
  isOnSale?: boolean;
  isBestSeller?: boolean;
  innerMessage?: boolean;
  isNewItem?: boolean;
  isComingSoon?: boolean;
  isLocked?: boolean;
  isPromo?: boolean;
  showSaleTag?: boolean;
  showPercentOff?: boolean;
  percentOff?: number;
  showAmountOff?: boolean;
  amountOff?: number;
  isOnClearance?: boolean;
  showStock?: boolean;
  stock?: number;
};

const getData = ({
  isLowStock,
  isOutOfStock,
  isOnSale,
  isComingSoon,
  isNewItem,
  isLocked,
  showSaleTag,
  isPromo,
  showPercentOff,
  percentOff,
  showAmountOff,
  amountOff,
  isBestSeller,
  isOnClearance,
}: {
  isLowStock: boolean;
  isOutOfStock: boolean;
  isOnSale: boolean;
  isNewItem?: boolean;
  isComingSoon?: boolean;
  isLocked?: boolean;
  showSaleTag?: boolean;
  isPromo?: boolean;
  showPercentOff?: boolean;
  percentOff?: number;
  showAmountOff?: boolean;
  amountOff: number;
  isBestSeller?: boolean;
  isOnClearance?: boolean;
}): { message: string; style: string } => {
  if (isLocked) {
    return { message: 'message_is_locked', style: styles.isLocked };
  }

  if (isComingSoon) {
    return { message: 'message_coming_soon', style: styles.comingSoon };
  }

  if (isOutOfStock) {
    return { message: 'message_out_of_stock', style: styles.outOfStock };
  }

  if (isPromo) {
    return { message: 'message_promotion', style: styles.isPromo };
  }

  if (showAmountOff && amountOff) {
    return { message: null, style: styles.onSale };
  }

  if (showPercentOff && percentOff) {
    return { message: 'discount_label', style: styles.onSale };
  }

  if (isOnClearance && isOnSale) {
    return { message: 'message_clearance', style: styles.onClearance };
  }

  if (isOnSale || showSaleTag) {
    return { message: 'message_on_sale', style: styles.onSale };
  }

  if (isBestSeller && isOnClearance) {
    return { message: 'message_clearance', style: styles.onClearance };
  }

  if (isBestSeller) {
    return { message: 'message_best_seller', style: styles.bestSeller };
  }

  if (isOnClearance && isLowStock) {
    return { message: 'message_clearance', style: styles.onClearance };
  }

  if (isLowStock) {
    return { message: 'message_low_stock', style: styles.lowStock };
  }

  if (isOnClearance && isNewItem) {
    return { message: 'message_clearance', style: styles.onClearance };
  }

  if (isNewItem) {
    return { message: 'message_new_item', style: styles.newItem };
  }

  if (isOnClearance) {
    return { message: 'message_clearance', style: styles.onClearance };
  }

  return null;
};

const AvailabilityMessage: FC<Props> = ({
  isBestSeller,
  isLowStock,
  isOutOfStock,
  isOnSale,
  innerMessage,
  isComingSoon,
  isNewItem,
  isLocked,
  showSaleTag,
  isPromo,
  showPercentOff,
  percentOff,
  showAmountOff,
  amountOff,
  isOnClearance,
  showStock,
  stock,
}) => {
  const MESSAGES = {
    message_is_locked: t('message_is_locked'),
    message_coming_soon: t('message_coming_soon'),
    message_out_of_stock: t('message_out_of_stock'),
    message_promotion: t('message_promotion'),
    discount_label: t('discount_label'),
    message_clearance: t('message_clearance'),
    message_on_sale: t('message_on_sale'),
    message_best_seller: t('message_best_seller'),
    message_low_stock: t('message_low_stock'),
    message_new_item: t('message_new_item'),
  };

  const currentData = useMemo(
    () =>
      getData({
        isLowStock,
        isOutOfStock,
        isOnSale,
        isComingSoon,
        isNewItem,
        isLocked,
        showSaleTag,
        isPromo,
        showPercentOff,
        percentOff,
        showAmountOff,
        amountOff,
        isBestSeller,
        isOnClearance,
      }),
    [
      isLowStock,
      isOutOfStock,
      isOnSale,
      isComingSoon,
      isNewItem,
      isLocked,
      showSaleTag,
      isPromo,
      showPercentOff,
      percentOff,
      showAmountOff,
      amountOff,
      isBestSeller,
      isOnClearance,
    ]
  );
  // Amount Off Numbers
  const router = useRouter();
  const { locale, defaultLocale } = router;
  const region = getRegion(locale);
  const currencyCode = useMemo(
    () => MAXIFY_CURRENCY_MAP_PER_REGION[region],
    [region]
  );

  const STOCK_MSG = t('message_stock_amount', { stock });

  const { price } = usePrice({
    amount: amountOff || 0,
    currencyCode,
  });

  const amountOffLabel =
    showAmountOff && amountOff
      ? getAmountOffLabel(locale, defaultLocale, price)
      : null;

  const composedClassName = useMemo(
    () => cn(styles.base(innerMessage), currentData && currentData.style),
    [innerMessage, currentData]
  );

  const showAmount = showAmountOff && amountOffLabel;
  const showPercent = showPercentOff && percentOff;

  const showPercentOffLabel =
    !isComingSoon && !isOutOfStock && showPercentOff && showPercent;
  const showAmountOffLabel =
    !isComingSoon && !isOutOfStock && showAmountOff && showAmount;

  const chipLabel = (): string => {
    const message = currentData?.message
      ? MESSAGES[currentData.message]
      : undefined;

    if (showAmountOffLabel) {
      return amountOffLabel;
    }
    if (showPercentOffLabel) {
      return `${percentOff}% ${message}`;
    }

    return message;
  };

  return (
    <div className={styles.wrapper}>
      {currentData && <p className={composedClassName}>{chipLabel()}</p>}
      {showStock && <p className={styles.stock}>{STOCK_MSG}</p>}
    </div>
  );
};

export default memo(AvailabilityMessage);
